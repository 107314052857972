<template>
  <v-dialog
      v-model="academyBookingDialog"
      max-width="765"
      @input="close"
      persistent
  >
    <v-card class="pa-0">
      <div class="pa-4">
        <div class="d-flex justify-space-between align-center border-bottom">
          <h3 class="font-semibold text-blue pb-2">
            Book Academy
          </h3>
          <div class="pointer" @click="close">
            <ModalCloseIcon/>
          </div>
        </div>
        <br>
        <v-row>
          <v-col class="py-1" md="6" sm="12">
            <label for="">Program*</label>
            <v-select
                v-model="bookingForm.program_id"
                :items="programs"
                :menu-props="{ bottom: true, offsetY: true }"
                :rules="[(v) => !!v || 'Program is Required']"
                background-color="#fff"
                class="q-autocomplete shadow-0"
                dense
                hide-details="auto"
                item-text="name"
                item-value="id"
                outlined
                validate-on-blur
                @change="programChange(), checkProrate()"
            />
          </v-col>
          <v-col class="py-1" md="6" sm="12">
            <label for="">Product*</label>
            <v-select
                v-model="product"
                :rules="[(v) => !!v.product_id || 'Product is required']"
                :items="productAndSchedules.pricing"
                item-text="name"
                item-value="product_id"
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                class="q-autocomplete shadow-0"
                dense
                hide-details="auto"
                outlined
                validate-on-blur
                return-object
                @change="programChange(); checkProrate()"
            ></v-select>
          </v-col>

          <v-col class="py-1" md="6" sm="12">
            <label for="">Price</label>
            <v-text-field
                readonly
                :disabled="!product.price"
                v-model="product.price"
                :prefix="currencyCode"
                append="Discount"
                background-color="#fff"
                class="q-text-field shadow-0"
                dense
                hide-details="auto"
                outlined
                validate-on-blur
                value="80"
            >
              <template v-if="product.discount" v-slot:append>
                <div class="text-decoration-line-through m-t-2 text-sm">
                  <div class="text-decoration-line-through">
                    {{ currencyCode }}
                    {{ product.discount.actual_price }}
                  </div>
                </div>
              </template>
            </v-text-field>
          </v-col>
          <v-col class="py-1" md="6" sm="12">
            <label for="">Level</label>
            <v-select
                v-model="bookingForm.level_id"
                :items="levels"
                item-text="name"
                :menu-props="{ bottom: true, offsetY: true }"
                background-color="#fff"
                class="q-autocomplete shadow-0"
                dense
                hide-details="auto"
                item-value="id"
                outlined
                validate-on-blur
            ></v-select>
          </v-col>
          <v-col class="py-1" md="6" sm="12">
            <label for="">Select class start date*</label>
            <date-field
                v-model="bookingForm.start_date"
                :backFill="checkBackfillPermission($modules.workshops.schedule.slug)"
                :hide-details="true"
                :rules="[(v) => !!v || 'Start date is required']"
                class-name="q-text-field shadow-0"
                dense
                label=""
                @change="programChange(), checkProrate()"
                :minDate="academyDetails.start_date"
                :maxDate="academyDetails.end_date"
            >
            </date-field>
          </v-col>
        </v-row>
        <v-row class="py-2"
               v-if="prorateDetails.dates && prorateDetails.dates.length && product && product.package_type == 1" dense>
          <v-alert
              type="info"
              outlined
              text
              style="margin: 5px auto !important"
          >
            Customer will be able to attend
            {{ prorateDetails.available_slots }}/{{ prorateDetails.total_slots }}
            classes which will start from
            {{ prorateDetails.dates[0].selected_date | dateformat }} and end by
            {{ prorateDetails.dates[prorateDetails.dates.length - 1].selected_date | dateformat }}. <br/>
            <b>Fee: {{ prorateDetails.price | toCurrency }}</b>
            <span class="text-decoration-line-through" v-if="prorateDetails.product.price > prorateDetails.price">
              {{ prorateDetails.product.price | toCurrency }}
            </span>
            <div class="mt-2">
              <b>Occurrences: </b>
              <span v-for="(date, index) in prorateDetails.dates" :key="index"
              >
                <span v-if="index == 0">{{ date.selected_date | dateformat }}</span>
                <span v-else-if="index == prorateDetails.dates.length - 1">and {{
                    date.selected_date | dateformat
                  }}</span>
                <span v-else>, {{ date.selected_date | dateformat }}</span>
              </span>
            </div>
          </v-alert>
        </v-row>
      </div>
      <template
          v-if="product && product.package_type && product.package_type == 1 && academyDetails.workshop_type_id == 1">
        <div v-for="(dateRange, drIndex) in productAndSchedules.date_ranges" :key="drIndex">
          <div class="date-range px-5 py-3 font-medium">
            Date Range: {{ dateRange.start_date | dateformat }} - {{ dateRange.end_date | dateformat }}
          </div>
          <div class="px-5 mt-4">
            <v-card :disabled="dateRange.end_date < bookingForm.start_date" class="mt-2" outlined>
              <v-card-text>
                <v-row
                    v-for="(days, index) in dateRange.selectedDays"
                    :key="index"
                    :disabled="dateRange.end_date < bookingForm.start_date ? true : false"
                >
                  <v-col sm="6">
                    <label for="">Day*</label>
                    <v-select
                        v-model="days.day"
                        :disabled="dateRange.end_date < bookingForm.start_date"
                        :items="dateRange.weekdays"
                        :menu-props="{ bottom: true, offsetY: true }"
                        background-color="#fff"
                        class="q-autocomplete shadow-0"
                        dense
                        hide-details="auto"
                        item-text="name"
                        item-value="id"
                        outlined
                        return-object
                        validate-on-blur
                        @change="daysChange(drIndex, index)"
                    ></v-select>
                  </v-col>

                  <v-col>
                    <label for="">Select Time slot</label>
                    <v-select
                        v-model="days.workshop_schedule_id"
                        :disabled="dateRange.end_date < bookingForm.start_date"
                        :items="days.day.time"
                        :menu-props="{ bottom: true, offsetY: true }"
                        :rules="dateRange.end_date < bookingForm.start_date? []: [(v) => !!v || 'Time Slot is required']"
                        background-color="#fff"
                        class="q-autocomplete shadow-0"
                        dense
                        hide-details="auto"
                        item-text="name"
                        item-value="workshop_schedule_id"
                        outlined
                        validate-on-blur
                        @change="checkProrate()"
                    >
                      <template slot="item" slot-scope="{ item, on, attrs }">
                        <v-list-item ripple v-bind="attrs" v-on="on">
                          <v-list-item-content>
                            <v-list-item-title>{{ item.name }}</v-list-item-title>
                            <v-list-item-subtitle>
                              <div class="line-clamp-1" style="max-width: 100px">{{ item.facility_name || "NA" }}</div>
                              <div class="line-clamp-1 mt-1" style="max-width: 100px">
                                {{ item.start_date | dateformat }} -to- {{ item.end_date | dateformat }}
                              </div>
                            </v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn fab icon v-bind="attrs" x-small v-on="on">
                                  <v-icon>mdi-information</v-icon>
                                </v-btn>
                              </template>
                              Trainers :- {{ item.trainer || "NA" }}
                            </v-tooltip>
                          </v-list-item-action>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <div class="add_btn" v-if="checkAddButtonStatus(drIndex) &&  bookingForm.start_date <= dateRange.end_date">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="blue-color"
                      dark
                      fab
                      v-bind="attrs"
                      x-small
                      v-on="on"
                      @click="addDay(drIndex)"
                  >
                    <v-icon>mdi-plus-circle</v-icon>
                  </v-btn>
                </template>
                Add Day
              </v-tooltip>
            </div>
          </div>
        </div>
      </template>

      <div class="mt-8 d-flex justify-end align-center bg-cyan pa-4">
        <v-btn class="white--text blue-color" height="40" text @click="openCustomerPopup">Next</v-btn>
      </div>
    </v-card>
    <ShopCustomerPopup
        v-if="showCustomerPopup"
        bookingType="academy"
        :promotions="promotions"
        :bookingData="bookingForm"
        :show-customer-popup="showCustomerPopup"
        @close="closeCustomerPopup"
        @setCustomers="save"
        :max-customers="1"
    />
    <PackageCardPopup v-if="offers && offers.length" :show-offer-popup="showOfferPopup" :offers="offers" @close="close" />
  </v-dialog>
</template>

<script>
import ModalCloseIcon from "@/assets/images/misc/modal-close.svg";
import moment from "moment";
import ShopCustomerPopup from "@/components/Shop/ShopCustomerPopup.vue";
import PackageCardPopup from "@/components/Shop/Offers/PackageCardPopup.vue";

export default {
  components: {
    PackageCardPopup,
    ShopCustomerPopup,
    ModalCloseIcon
  },
  props: {
    academyPackageModal: { type: Boolean, default: false },
    academy: {
      type: Object,
      required: true
    },
    product_id: { type: Number, default: 0 },
    is_filter_product: { type: Boolean, default: false },
  },
  watch: {
    academyPackageModal(val) {
      if (val) {
        this.getDetails(this.academy.id)
      }
    },
  },
  mounted() {
    if (this.$store.getters.getWeekdays.status === false) {
      this.$store.dispatch("loadWeekdays");
    }
    if(this.academy && this.academy.id){
      this.getDetails(this.academy.id);
      if (this.$store.getters.getShopBookingFields({type: "academy"}).length === 0) {
        this.$store.dispatch("loadShopBookingFields", {type:"academy"}).then( () => {
          console.log("loading academy config")
        });
      }
    }
  },
  computed: {
    getCurrentVenueId() {
      if(this.academy && this.academy.venue_id){
        return this.academy.venue_id;
      }
      return this.$store.getters.svid;
    },
    academyBookingDialog: {
      get() {
        return this.academyPackageModal
      },
      set(value) {
        this.$emit('close', value)
      }
    },
    getWeekDays() {

      return JSON.parse(JSON.stringify(this.$store.getters.getWeekdays.data));
    },
    promotions() {
      return this.$store.getters.getShopPromotions.data;
    },

  },

  data() {
    return {
      offers: [],
      showOfferPopup: false,
      showCustomerPopup: false,
      date_ranges: [],
      academyDetails: {},
      programs: [],
      productAndSchedules: {
        products: [],
      },
      package_type: null,
      product: {price: null,},
      levels: [],
      prorateDetails: {},
      bookingForm: {
        venue_id: null,
        start_date: moment().format('YYYY-MM-DD'),
        level_id: null,
        program_id: null,
        products: [],
        discount: null,
        promotion_code: null
      }
    }
  },
  methods: {
    addDay(drIndex = null) {
      this.checkAddButtonStatus(drIndex)
      if (this.checkAddButtonStatus(drIndex)) {
        this.productAndSchedules.date_ranges[drIndex].selectedDays.push({
          day: {time: []},
        });
        this.$forceUpdate();
      }
    },
    closeCustomerPopup() {
      this.showCustomerPopup = false;
      this.ticketModal = false;
      this.bookingForm.products = [];
    },
    openCustomerPopup() {
      let checkPass = true;
      if (!this.bookingForm.program_id) {
        checkPass = false;
        this.showError("Please select program! ");
        return;
      }
      if (this.prorateDetails && this.prorateDetails.available_slots == 0) {
        checkPass = false;
        this.showError("No class available for this day. Please choose another day! ");
        return;
      }
      this.product.quantity = 1;
      this.product.workshop_id = this.academy.id;

      if (this.product.package_type === 1 && this.academyDetails.workshop_type_id === 1) {
        this.productAndSchedules.date_ranges.forEach((dRange, drIndex) => {
          if (dRange.selectedDays && this.bookingForm.start_date <= dRange.end_date) {
            let last = dRange.selectedDays[dRange.selectedDays.length - 1];
            if (this.checkAddButtonStatus(drIndex) || !last.day || !last.day.bit_value || !last.workshop_schedule_id) {
              checkPass = false;
              this.showError("Please select all schedules");
              return 0;
            }
          }
        });
      }
      if (checkPass) {
        this.bookingForm.products.push({...this.product});
        this.$store.dispatch("loadShopPromotions", {
          date: this.bookingForm.start_date,
          product_type: "Academy",
          venue_service_id: this.academyDetails.venue_service_id,
          venueId: this.getCurrentVenueId,
        }).then(() => {
          this.showCustomerPopup = true;
        });

      }
    },
    getDetails(id) {
      this.resetBookingForm();
      this.showLoader("Loading..");

      this.$http.get(`venues/shop/academies/details/${id}?venueId=${this.getCurrentVenueId}`)
          .then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              if(this.is_filter_product && this.product_id > 0){
                this.filterProgramsByProductId(data,this.product_id);
                this.academyDetails = JSON.parse(JSON.stringify(data));
                this.programs =this.academyDetails.programs;
              }else{
                this.academyDetails = data;
                this.programs = data.programs;
              }
              if(this.academy && this.academy.venue_id){
                this.bookingForm.venue_id = this.academy.venue_id;
              }

              this.hideLoader();
            }
          })
          .catch((error) => {
            this.errorChecker(error);
          });
    },
    programChange() {
      if (this.bookingForm.start_date < this.academyDetails.start_date) {
        this.showError("Workshop start date is " + this.academyDetails.start_date);
        return;
      } else if (this.bookingForm.start_date > this.academyDetails.end_date) {
        this.showError("Workshop end date is " + this.academyDetails.end_date);
        return;
      }
      this.productAndSchedules = {
        products: [],
        date_ranges: [],
      };
      let program = this.programs.find((x) => x.id == this.bookingForm.program_id);
      this.productAndSchedules.date_ranges = program.date_ranges;
      let pricing = [];

      let groupedPackages = program.pricing.reduce((acc, pkg) => {
        // Find if the package_type_id already exists in the accumulator
        let group = acc.find(g => g.package_type === pkg.package_type);
        if (group) {
          // If it exists, add the package to the items array
          group.items.push(pkg);
        } else {
          // If it doesn't exist, create a new group and add the package to the items array
          acc.push({
            package_type: pkg.package_type,
            items: [pkg]
          });
        }
        return acc;
      }, []);


      groupedPackages.forEach((item) => {

        let title = this.getTitle(item.package_type);

        pricing.push({header: title});
        pricing.push({divider: true});

        item.items.forEach((pkg) => {
          pricing.push(pkg);
        });
      });
      this.productAndSchedules.pricing = pricing;
      this.productAndSchedules.date_ranges.forEach((dateRange, drIndex) => {
        let weekDays = [];
        if (this.productAndSchedules.date_ranges[drIndex].weekdays) {
          this.productAndSchedules.date_ranges[drIndex].weekdays = null;
        }
        dateRange.schedules.forEach((schedule) => {
          schedule.weekdays.forEach((element) => {

            let findValue = this.getWeekDays.find((y) => y.bit_value === element);
            let obj = {
              trainer: schedule.trainer.map((trainer) => trainer.first_name + trainer.last_name).join(","),
              facility_name: schedule.facility_name ? schedule.facility_name : schedule.location,
              workshop_schedule_id: schedule.id,
              start_time: schedule.start_time,
              end_time: schedule.end_time,
              start_date: dateRange.start_date,
              end_date: dateRange.end_date,
              name: moment(schedule.start_time, "HH:mm").format("h:mm a") + " To " + moment(schedule.end_time, "HH:mm").format("h:mm a"),
            };
            let weekDaysIndex = weekDays.findIndex((x) => x.bit_value == element);
            if (weekDaysIndex !== -1) {
              weekDays[weekDaysIndex].time.push(obj);
            } else {
              findValue.time = [];
              findValue.time.push(obj);
              weekDays.push(findValue);
            }
          });
        });
        if (this.productAndSchedules.date_ranges[drIndex].weekdays) {
          this.productAndSchedules.date_ranges[drIndex].weekdays.push(JSON.parse(JSON.stringify(weekDays)));
        } else {
          this.productAndSchedules.date_ranges[drIndex].weekdays = {};
          this.productAndSchedules.date_ranges[drIndex].weekdays = JSON.parse(JSON.stringify(weekDays));
        }
        this.productAndSchedules.date_ranges[drIndex].selectedDays = [
          {day: {time: []}},
        ];
      });
    },
    checkProrate() {
      this.prorateDetails = {};
      let isProrateCheck = false;
      if (this.productAndSchedules.date_ranges && this.product.package_type == 1 && this.academyDetails.workshop_type_id == 1) {
        this.productAndSchedules.date_ranges.forEach((dRange, drIndex) => {
          if (dRange.selectedDays && this.bookingForm.start_date <= dRange.end_date) {
            let last = dRange.selectedDays[dRange.selectedDays.length - 1];
            if (
                this.checkAddButtonStatus(drIndex) ||
                !last.day ||
                !last.day.bit_value ||
                !last.workshop_schedule_id
            ) {
              isProrateCheck = false;
              return;
            } else {
              isProrateCheck = true;
            }
          }
        });
        if (isProrateCheck) {
          let formData = {};
          formData.workshop_program_id = this.bookingForm.program_id;
          formData.product_id = this.product.product_id;
          formData.start_date = this.bookingForm.start_date;
          formData.days = [];
          this.productAndSchedules.date_ranges.forEach((dRange) => {
            if (dRange.selectedDays) {
              dRange.selectedDays.forEach((el) => {
                if (el.day.bit_value && el.workshop_schedule_id) {
                  let day = {};
                  day.bit_value = el.day.bit_value;
                  day.date_range_id = dRange.id;
                  day.workshop_schedule_id = el.workshop_schedule_id;
                  day.start_date = el.day.time.find((x) => x.workshop_schedule_id == el.workshop_schedule_id).start_date;
                  day.end_date = el.day.time.find((x) => x.workshop_schedule_id == el.workshop_schedule_id).end_date;
                  formData.days.push(day);
                }
              });
            }
          });

          if (
              !formData.workshop_program_id ||
              !formData.product_id ||
              formData.days.length == 0
          )
            return;
          this.showLoader("Price Calculate ...");
          this.$http.post(`venues/workshops/schedules/booking/check`, formData).then((response) => {
            if (response.status == 200 && response.data.status == true) {
              const data = response.data.data;
              this.prorateDetails = data;
              if (data.product.allow_pro_rate == 1) {
                this.product.price = data.price;
                this.product.total_price = data.price + data.tax_amount;
                this.product.tax = data.tax_amount;
              } else {
                this.prorateDetails.price = this.product.price;
              }
              this.$forceUpdate();
            }
            this.hideLoader();
          })
              .catch((error) => {
                this.hideLoader();
                this.prorateDetails = {};
                this.errorChecker(error);
              });
        }
      }
    },
    daysChange(drIndex, index) {
      if (this.productAndSchedules.date_ranges[drIndex]["selectedDays"] && this.productAndSchedules.date_ranges[drIndex]["selectedDays"][index].day.time.length == 1) {
        this.productAndSchedules.date_ranges[drIndex]["selectedDays"][index].workshop_schedule_id = this.productAndSchedules.date_ranges[drIndex]["selectedDays"][index].day.time[0].workshop_schedule_id;
        this.$forceUpdate();
        this.checkProrate();
      } else {
        this.$forceUpdate();
        this.checkProrate();
      }
    },
    checkAddButtonStatus(drIndex = null) {
      if (this.productAndSchedules.date_ranges[drIndex]) {
        if (this.productAndSchedules.date_ranges[drIndex].selectedDays) {
          return (this.productAndSchedules.date_ranges[drIndex].selectedDays.length < this.product.frequency);
        } else {
          return false;
        }
      }
    },
    close() {
      this.showCustomerPopup = false;
      this.date_ranges = [];
      this.academyDetails = {};
      this.programs = [];
      this.prorateDetails = {};
      this.product = {price: null,};
      this.productAndSchedules = {
        products: [],
      }
      this.showOfferPopup = false;
      this.offers = [];
      this.$emit('close');
    },
    reset() {
      this.editFlag = false;
      this.product = {price: null,};
      this.productAndSchedules = {products: [],};
      this.weekdays = [];
      this.selectedDays = [{day: {time: []}}];
      this.bookingForm = {discount: null};

    },
    save(data) {
      let cartData = {products: [], date_ranges: []};
      let program = this.programs.find((x) => x.id == this.bookingForm.program_id);
      cartData.type = "academy";
      cartData.venueId = this.getCurrentVenueId;
      cartData.workshop_id = this.academy.id;
      cartData.start_date = this.bookingForm.start_date;
      cartData.workshop_type_id = this.academyDetails.workshop_type_id;
      cartData.venue_service_id = this.academyDetails.venue_service_id;
      cartData.program_id = this.bookingForm.program_id;
      cartData.product_id = this.product.product_id;
      cartData.package_type = this.product.package_type;
      cartData.level_id = this.bookingForm.level_id ? this.bookingForm.level_id.id : null;
      cartData.program_name = program.name;
      cartData.discount = this.bookingForm.discount ? this.bookingForm.discount : null;
      if (this.bookingForm.promotion_code) {
        cartData.promotion_code = this.bookingForm.promotion_code;
      }
      let pushObject = this.bookingForm.products.filter(ele => ele.quantity > 0);
      cartData.products = [...cartData.products, ...pushObject];

      this.product.quantity = 1;
      data.forEach((item) => {
        delete item.search;
        delete item.nameSearch;
      });
      cartData.customers = data;
      if (this.product.package_type == 1 && this.academyDetails.workshop_type_id == 1) {
        this.productAndSchedules.date_ranges.forEach((dRange) => {
          if (dRange.selectedDays && this.bookingForm.start_date <= dRange.end_date) {
            let dateRange = {
              date_range_id: dRange.id,
              start_date: dRange.start_date,
              end_date: dRange.end_date,
              days: []
            };

            dRange.selectedDays.forEach((el) => {
              // Find the corresponding time object
              const timeObj = el.day.time.find((x) => x.workshop_schedule_id == el.workshop_schedule_id);

              // Initialize the day object
              let day = {
                bit_value: el.day.bit_value,
                workshop_schedule_id: el.workshop_schedule_id,
                start_date: timeObj ? timeObj.start_date : null,
                end_date: timeObj ? timeObj.end_date : null
              };
              dateRange.days.push(day);
            });

            cartData.date_ranges.push(dateRange);
          }
        });
      }
      // if(this.product.package_type == 1 && this.academyDetails.workshop_type_id == 1){
      //   this.productAndSchedules.date_ranges.forEach((dRange, dIndex) => {
      //     if (dRange.selectedDays && this.startDate <= dRange.end_date) {
      //       cartData.date_ranges[dIndex] = {
      //         date_range_id: dRange.id,
      //         start_date: dRange.start_date,
      //         end_date: dRange.end_date,
      //         days: []
      //       };
      //       // cartData.date_ranges[dIndex]['date_range_id'] = dRange.id;
      //       // cartData.date_ranges[dIndex]['start_date'] =  dRange.start_date;
      //       // cartData.date_ranges[dIndex]['end_date'] =  dRange.end_date;

      //       dRange.selectedDays.forEach((el, index) => {
      //         // Initialize the day object
      //         cartData.date_ranges[dIndex].days[index] = {
      //           bit_value: el.day.bit_value,
      //           workshop_schedule_id: el.day.workshop_schedule_id,
      //           start_date: el.day.time.find((x) => x.workshop_schedule_id == el.workshop_schedule_id).start_date,
      //           end_date: el.day.time.find((x) => x.workshop_schedule_id == el.workshop_schedule_id).end_date
      //         };
      //         // cartData.date_ranges[dIndex]['days'][index]['bit_value'] = el.day.bit_value;
      //         // cartData.date_ranges[dIndex]['days'][index]['workshop_schedule_id'] = el.day.workshop_schedule_id;
      //         // cartData.date_ranges[dIndex]['days'][index]['start_date'] = el.day.time.find((x) => x.workshop_schedule_id == el.workshop_schedule_id).start_date;
      //         // cartData.date_ranges[dIndex]['days'][index]['end_date'] = el.day.time.find((x) => x.workshop_schedule_id == el.workshop_schedule_id).end_date;
      //       });
      //     }
      //   });
      // }
      this.showCustomerPopup = false;
      this.$store.dispatch("addToCart", cartData).then( () => {
        this.checkProductOffers();
      });
      this.close();
    },
    getTitle(index) {
      let arr = [
        {index: 1, name: 'Periodic Slots'},
        {index: 2, name: 'Session Based'},
        {index: 3, name: 'Program Based'}
      ];
      return this.findNameByIndex(arr, index);
    },
    findNameByIndex(arr, index) {
      const item = arr.find(item => item.index === index);
      return item ? item.name : 'Generic';
    },
    resetBookingForm() {
      this.bookingForm = {
        start_date: moment().format('YYYY-MM-DD'),
        level_id: null,
        program_id: null,
        products: [],
        discount: null,
        promotion_code: null
      }
    },
    checkProductOffers(){
      this.offers = [];
      this.$store.dispatch('searchOffer').then( (response) => {
        if (response && response.status == 200 && response.data.status == true) {
          const offerPackages =response.data.data || null;
          if(offerPackages && offerPackages.length > 0) {
            this.offers = offerPackages;
            this.showOfferPopup = true;
          }else{
            this.offers = [];
          }
        }
      });
    },
    filterProgramsByProductId(data, product_id) {
      // Filter programs based on the existence of pricing with the given product_id
      data.programs = data.programs.filter(program => {
        // Filter the pricing array within each program
        program.pricing = program.pricing.filter(pricing => pricing.product_id === product_id);

        // Return the program only if it has at least one matching pricing item
        return program.pricing.length > 0;
      });

      return data; // Return the modified original data
    }
  },
}
</script>

<style lang="scss" scoped>
.date-range {
  background: #F7F9FB;
}
</style>
